import {defineStore} from 'pinia'
import {API} from '@/util/APIroute'



export const User = defineStore('user', {
    state: () =>({
        // userId: '',
        role: '',
        message: '',
        status: null,
        auth: null,
        error: null,
        token: JSON.parse(sessionStorage.getItem('user')).token,
        userId: JSON.parse(sessionStorage.getItem('user'))._id
    }),

    actions: {
        async getUser(){
            try {
                this.status= null
                this.message = null
                this.error = null
                // const token = JSON.parse(sessionStorage.getItem('user')).token
                // const userId = JSON.parse(sessionStorage.getItem('user'))._id

                    const res = await fetch(`${API}/${this.role}/${this.userId}/user/${this.userId}`, {
                    method: "GET",
                    headers:{
                         accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: this.token
                    }
                })


                const data = await res.json()

                if(data.status){
                    this.status = true
                    this.auth = data.data.tfaAuth
                }else{
                    this.status = false
                    this.message = data.message
                }


            } catch (error) {
                this.status= false,
                this.error = error
                console.log(error)
            }
        }
    }
})

export const Auth = defineStore('auth', {
    state: ()=>({
        role: "",
        message: null,
        status: null,
        authToken: null,
        error: null,
        toggleAuth: null,
        AuthData: null,
        userData: null,
        password: null,
    }),

    actions:{
        async generateAuth(){
            try {
                this.status= null
                this.message = null
                this.error = null
                this.authToken = null
                this.AuthData = null
                const token = JSON.parse(sessionStorage.getItem('user')).token
                const userId = JSON.parse(sessionStorage.getItem('user'))._id
                const res = await fetch(`${API}/${this.role}/${userId}/generateAuth?userId=${userId}`, {
                    method: "GET",
                    headers:{
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: token
                    }
                })
                const data = await res.json()
                if(data.status){
                    this.status = true
                    this.message = data.message
                    this.AuthData = data.data
                }else{
                    this.status = false,
                    this.message = data.message
                }
            } catch (error) {
                console.log(error)
                this.error = error
            }
        },
        async enableAuth(){
            try {
                this.status= null
                this.message = null
                this.error = null
                const token = JSON.parse(sessionStorage.getItem('user')).token
                const userId = JSON.parse(sessionStorage.getItem('user'))._id
                const form = {
                    token: this.authToken
                }
                const res = await fetch(`${API}/${this.role}/${userId}/enableAuth?userId=${userId}`, {
                    method: "POST",
                    headers:{
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: token
                    },
                    body: JSON.stringify(form)
                })
                const data = await res.json()
                if(data.status){
                    this.status = true
                    this.message = data.message
                }else{
                    this.status = false,
                    this.message = data.message
                }
            } catch (error) {
                console.log(error)
                this.error = error
            }
        },
        async disableAuth(){
            try {
                this.status= null
                this.message = null
                this.error = null
                const token = JSON.parse(sessionStorage.getItem('user')).token
                const userId = JSON.parse(sessionStorage.getItem('user'))._id
                const form = {
                    token: this.authToken
                }
                const res = await fetch(`${API}/${this.role}/${userId}/disableAuth?userId=${userId}`, {
                    method: "POST",
                    headers:{
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: token
                    },
                    body: JSON.stringify(form)
                })
                const data = await res.json()
                if(data.status){
                    this.status = true
                    this.message = data.message
                }else{
                    this.status = false,
                    this.message = data.message
                }
            } catch (error) {
                console.log(error)
                this.error = error
            }
        },
        async validateAuth(){
            try {
                this.status= null
                this.message = null
                this.error = null
                const form = {
                    token: this.authToken
                }
                const res = await fetch(`${API}/${this.role}/${this.userData._id}/validateAuth?userId=${this.userData._id}`, {
                    method: "POST",
                    headers:{
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: this.userData.token
                    },
                    body: JSON.stringify(form)
                })
                const data = await res.json()
                if(data.status){
                    this.status = true
                    this.message = data.message
                }else{
                    this.status = false,
                    this.message = data.message
                }
            } catch (error) {
                console.log(error)
                this.error = error
            }
        },
        async recoveryOption(){
           try {
            this.status= null
            this.message = null
            this.error = null
            const token = JSON.parse(sessionStorage.getItem('user')).token
            const userId = JSON.parse(sessionStorage.getItem('user'))._id
            const form = {
                password: this.password
            }
            const res = await fetch(`${API}/${this.role}/${userId}/recoveryOption?userId=${userId}`, {
                method: "POST",
                headers:{
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify(form)
            })
            const data = await res.json()

            if(data.status){
                this.status = true
                this.AuthData = data.data
            }else{
                this.message = data.message
            }
           } catch (error) {
            this.error = error,
            console.log(error)
           }
        }

    }
})
