import { createRouter, createWebHistory } from "vue-router";

//Admin Login
import AdminLogin from "../views/adminLogin/Login.vue";
import AdminDash from "@/views/dashboard/dashboard.vue"

//catchall 404
import NotFound from "../views/NotFound.vue";

const routes = [
  { path: "/", redirect: "/admin/login" },


  // { path: "/terms",
  //   redirect: (to) => {
  //     window.location.href = "https://drive.google.com/file/d/1qUofCRG59GKtwkrnpmFc1FAMQiDotVgM/view?usp=drive_link"
  //   }
  // },
  // {
  //   path: "/privacy-policy",
  //   redirect: (to) =>{
  //     window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
  //   }
  // },

  // {
  //   path: "/privacy-policy",
  //   name: "Privacy",
  //   component: () => import("@/views/Privacy")
  // },
  // { path: "/privacy", redirect: "/privacy-policy" },
  // { path: "/paia", redirect: (to) =>{
  //   window.location.href = "https://drive.google.com/file/d/1oIOvG-MRfTfQVJor6uU22FAvDvjkM3vy/view?usp=drive_link"
  // } },

  // { path: "/privacy",
  //   redirect: (to) =>{
  //   window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
  // } },


  // {
  //   path: "/forgot-password",
  //   name: "ForgotPassword",
  //   component: () => import("@/views/userAuth/ForgotPassword.vue")
  // },
  // {
  //   path: "/reset-password",
  //   name: "ResetPassword",
  //   component: () => import("@/views/userAuth/ResetPassword.vue")
  // },




  //////////Dashboard
  { path: "/admin/login", name: "AdminLogin", component: AdminLogin },

  {
    path: "/dashboard",
    name: "AdminDashboard",
    component: AdminDash,
    children:[
       //redirect to dashboard page
       { path: "/dashboard", redirect: "/dashboard/home" },
      {
        path: "home",
        name: "DashHome",
        component: () => import("@/views/dashboard/Home.vue")
      },
      {
        path:"plan",
        name: "DashPlan",
        component: () => import("@/views/dashboard/plan.vue")
      },
      {
        path: "edit-plan/:id",
        name: "EditPlan",
        component: () => import("@/views/dashboard/planEdit.vue"),
        props: true
      },
      {
        path: "add-faq",
        name: "DashAddFaq",
        component: () => import("@/views/dashboard/addFaq.vue")
      },
      {
        path:"faq",
        name: "DashFaq",
        component: () => import("@/views/dashboard/faq.vue")
      },
      {
        path: "edit-faq/:id",
        name: "EditFaq",
        component: () => import("@/views/dashboard/faqEdit.vue"),
        props: true
      },
      {
        path: "profile",
        name: "DashProfile",
        component: () => import("@/views/dashboard/Profile.vue")
      },
      //notifications
      {
        path: "all-notifications",
        name: "DashAllNotifications",
        component: () => import("@/views/dashboard/AllNotifications.vue")
      },
      //products
      {
        path: "active-products",
        name: "DashViewProductsActive",
        component: () => import("@/views/dashboard/ViewProductsActive.vue")
      },
      {
        path: "edit-product-status",
        name: "DashEditProductStatus",
        component: () => import("@/views/dashboard/EditProductStatus.vue")
      },
      {
        path: "pending-products",
        name: "DashViewProductsPending",
        component: () => import("@/views/dashboard/ViewProductsPending.vue")
      },
      {
        path: "supplierReportDetails/:id",
        name: "DashSupplierReportDetails",
        component: () => import("@/views/dashboard/Supplier001"),
        props: true
      },
      {
        path: "buyerReportDetails/:id",
        name: "DashBuyerReportDetails",
        component: () => import("@/views/dashboard/Buyer001"),
        props: true
      },
      {
        path: "disable-products",
        name: "DashViewProductsDisable",
        component: () => import("@/views/dashboard/ViewProductsDisable.vue")
      },

      //Help Video
      {
        path: "create-help-video",
        name: "DashCreateHelpVideo",
        component: () => import("@/views/dashboard/CreateHelpVideo.vue")
      },
      {
        path: "edit-help-video/:id",
        name: "DashEditHelpVideo",
        component: () => import("@/views/dashboard/EditHelpVideo.vue")
      },
      {
        path: "buyer-help-video",
        name: "DasAdminBuyerHelpVideo",
        component: () => import("@/views/dashboard/BuyerHelpVideo.vue")
      },
      {
        path: "supplier-help-video",
        name: "DashAdminSupplierHelpVideo",
        component: () => import("@/views/dashboard/SupplierHelpVideo.vue")
      },

      //Transaction
      {
        path: "transaction",
        name: "DashTranaction",
        component: () => import("@/views/dashboard/transaction")
      },
      {
        path: "transactionDetails/:id",
        name: "DashTransactionDetails",
        component: () => import("@/views/dashboard/transactionDetails"),
        props: true
      },
      //orders
      {
        path: "view-orders",
        name: "DashViewOrders",
        component: () => import("@/views/dashboard/ViewOrders.vue")
      },
      {
        path: "view-orders-closed",
        name: "DashViewOrdersClosed",
        component: () => import("@/views/dashboard/ViewOrdersClosed.vue")
      },
      // {
      //   path: "view-orders-cancelled",
      //   name: "DashViewOrdersCancelled",
      //   component: () => import("@/views/dashboard/ViewOrdersCancelled.vue")
      // },
      {
        path: "view-cancelled-order/:id",
        name: "DashOrderViewCancelled",
        component: () => import("@/views/dashboard/OrderViewCancelled.vue"),
        prop: true
      },
      {
        path: "view-order/:id",
        name: "DashViewOrderSingleOrder",
        component: () => import("@/views/dashboard/view-order"),
        props: true
      },
      //buyers
      {
        path: "active-buyers",
        name: "DashViewBuyersActive",
        component: () => import("@/views/dashboard/ViewBuyersActive.vue")
      },
      {
        path: "edit-buyer-content",
        name: "DashEditBuyerContent",
        component: () => import("@/views/dashboard/EditBuyerContent.vue")
      },
      {
        path: "incomplete-buyers",
        name: "DashViewBuyersIncomplete",
        component: () => import("@/views/dashboard/ViewBuyersIncomplete.vue")
      },
      {
        path: "disable-buyers",
        name: "DashViewBuyersDisable",
        component: () => import("@/views/dashboard/ViewBuyersDisable.vue")
      },
      //suppliers
      {
        path: "active-suppliers",
        name: "DashViewSuppliersActive",
        component: () => import("@/views/dashboard/ViewSuppliersActive.vue")
      },
      {
        path: "edit-supplier-content/:id",
        name: "DashEditSupplierContent",
        component: () => import("@/views/dashboard/EditSupplierContent.vue"),
        props: true
      },
      {
        path: "view-supplier-content/:id",
        name: "DashViewSupplierContent",
        component: () => import("@/views/dashboard/viewSupplierContent"),
        props: true
      },
      {
        path: "view-buyer-content/:id",
        name: "DashViewBuyerContent",
        component: () => import("@/views/dashboard/viewBuyerContent"),
        props: true
      },
      // {
      //   path: "view-audit/:id",
      //   name: "DashViewAudit",
      //   component: () => import("@/views/dashboard/viewAudit"),
      //   props: true
      // },
      // {
      //   path: "addSupplierAudit/:id",
      //   name: "DashAddSupplierAudit",
      //   component: () => import("@/views/dashboard/addSupplierAudit"),
      //   props: true
      // },
      // {
      //   path: "pending-suppliers",
      //   name: "DashViewSuppliersPending",
      //   component: () => import("@/views/dashboard/ViewSuppliersPending.vue")
      // },
      {
        path: "incomplete-suppliers",
        name: "DashViewSuppliersIncomplete",
        component: () => import("@/views/dashboard/ViewSuppliersIncomplete")
      },
      {
        path: "disable-suppliers",
        name: "DashViewSuppliersDisable",
        component: () => import("@/views/dashboard/ViewSuppliersDisable.vue")
      },
      //shipper
      {
        path: "add-shipper",
        name: "DashAddShipper",
        component: () => import("@/views/dashboard/AddShipper.vue")
      },
      {
        path: "view-shipper",
        name: "DashViewShipper",
        component: () => import("@/views/dashboard/ViewShipper.vue")
      },
      {
        path: "edit-shipper-content/:id",
        name: "DashEditShipperContent",
        component: () => import("@/views/dashboard/EditShipperContent.vue"),
        props: true
      },
      //inspector
      // {
      //   path: "add-inspector",
      //   name: "DashAddInspector",
      //   component: () => import("@/views/dashboard/AddInspector.vue")
      // },
      // {
      //   path: "view-inspectors",
      //   name: "DashViewInspector",
      //   component: () => import("@/views/dashboard/ViewInspector.vue")
      // },
      // {
      //   path: "edit-inspector-content/:id",
      //   name: "DashEditInspectorContent",
      //   component: () => import("@/views/dashboard/EditInspectorContent.vue"),
      //   props: true
      // },

      //Product Request
      {
        path: "product-request",
        name: "DashViewProductRequest",
        component: () => import("@/views/dashboard/productRequest")
      },

      {
        path: "product-request/:id",
        name: "DashViewProductRequestDetails",
        component: () => import("@/views/dashboard/productRequestDetails"),
        props: true
      },

      //quotes
      {
        path: "view-quotes",
        name: "DashViewQuotes",
        component: () => import("@/views/dashboard/ViewQuotes.vue")
      },
      {
        path: "view-quotes-closed",
        name: "DashViewQuotesClosed",
        component: () => import("@/views/dashboard/ViewQuotesClosed.vue")
      },
      {
        path: "view-quotes-cancelled",
        name: "DashViewQuotesCancelled",
        component: () => import("@/views/dashboard/ViewQuotesCancelled.vue")
      },
      {
        path: "quotes/:id",
        name: "DashQuotes0001",
        component: () => import("@/views/dashboard/Quotes0001.vue"),
        props: true
      },
      {
        path: "shipping-price/:id",
        name: "DashEnterShippingPrice",
        component: () => import("@/views/dashboard/EnterShippingPrice.vue"),
        props: true
      },
      {
        path: "edit-shipping-price/:id",
        name: "EditDashEnterShippingPrice",
        component: () => import("@/views/dashboard/EditEnterShippingPrice.vue"),
        props: true
      },
      // {
      //   path: "inspection-price/:id",
      //   name: "DashEnterSInspectionPrice",
      //   component: () => import("@/views/dashboard/EnterInspectionPrice.vue"),
      //   props: true
      // },
      // {
      //   path: "edit-inspection-price/:id",
      //   name: "EditDashEnterSInspectionPrice",
      //   component: () => import("@/views/dashboard/EditEnterInspectionPrice.vue"),
      //   props: true
      // },
      //blogs
      {
        path: "write-blog",
        name: "DashWriteBlog",
        component: () => import("@/views/dashboard/WriteBlog.vue")
      },
      {
        path: "edit-blog-content/:id",
        name: "DashEditBlogContent",
        component: () => import("@/views/dashboard/EditBlogContent.vue"),
        props: true
      },
      {
        path: "view-blog",
        name: "DashViewBlog",
        component: () => import("@/views/dashboard/ViewBlog.vue")
      },
      //emails
      {
        path: "buyer-email",
        name: "DashEmailBuyer",
        component: () => import("@/views/dashboard/EmailBuyer.vue")
      },
      {
        path: "supplier-email",
        name: "DashEmailSupplier",
        component: () => import("@/views/dashboard/EmailSupplier.vue")
      },
      {
        path: "search-result",
        name: "DashSearchResult",
        component: () => import("@/views/dashboard/searchResult")
      },
      // {
      //   path: "inspector-email",
      //   name: "DashEmailInspector",
      //   component: () => import("@/views/dashboard/EmailInspector.vue")
      // },
      {
        path: "shipper-email",
        name: "DashEmailShipper",
        component: () => import("@/views/dashboard/EmailShipper.vue")
      },
      {
        path: "edit-buyer-email/:id",
        name: "EditBuyerEmail",
        component: () => import("@/views/dashboard/EditBuyerEmail.vue"),
        props: true
      },
      {
        path: "edit-supplier-email/:id",
        name: "EditSupplierEmail",
        component: () => import("@/views/dashboard/EditSupplierEmail.vue"),
        props: true
      },
      {
        path: "edit-shipper-email/:id",
        name: "EditShipperEmail",
        component: () => import("@/views/dashboard/EditShipperEmail.vue"),
        props: true
      },
      // {
      //   path: "edit-inspector-email/:id",
      //   name: "EditInspectorEmail",
      //   component: () => import("@/views/dashboard/EditInspectorEmail.vue"),
      //   props: true
      // },
      //access level
      {
        path: "create-admin",
        name: "DashCreateAdmin",
        component: () => import("@/views/dashboard/CreateAdmin.vue")
      },
      {
        path: "view-admin",
        name: "DashViewAdmin",
        component: () => import("@/views/dashboard/ViewAdmin.vue")
      },
      {
        path: "manage-admin/:id",
        name: "DashManageAdmin",
        component: () => import("@/views/dashboard/ManageAdmin.vue"),
        props: true
      },
      //reports
      {
        path: "user-reports",
        name: "DashUserReports",
        component: () => import("@/views/dashboard/UserReports.vue")
      },
      {
        path: "quote-reports",
        name: "DashQuoteReports",
        component: () => import("@/views/dashboard/QuoteReports.vue")
      },
      {
        path: "order-reports",
        name: "DashOrderReports",
        component: () => import("@/views/dashboard/OrderReports.vue")
      },
      {
        path: "buyer-reports",
        name: "DashBuyerReports",
        component: () => import("@/views/dashboard/BuyerReports.vue")
      },
      // {
      //   path: "buyer001",
      //   name: "DashBuyer001",
      //   component: () => import("@/views/dashboard/Buyer001.vue")
      // },
      {
        path: "supplier-reports",
        name: "DashSupplierReports",
        component: () => import("@/views/dashboard/SupplierReports.vue")
      },
      // {
      //   path: "supplier001",
      //   name: "DashSupplier001",
      //   component: () => import("@/views/dashboard/Supplier001.vue")
      // },
      //Blog
      {
        path: "add-blog",
        name: "AddBlog",
        component: () => import("@/views/dashboard/addBlog.vue")
      },
      //settings
      {
        path: "edit-profile-details",
        name: "DashEditProfileDetails",
        component: () => import("@/views/dashboard/EditProfileDetails.vue")
      },
      {
        path: "change-password",
        name: "DashChangePassword",
        component: () => import("@/views/dashboard/ChangePassword.vue")
      },
      {
        path: 'analytics',
        name: "DashAnalytics",
        component: () => import("@/views/dashboard/Analytics.vue")
      },
      {
        path: "2fa-auth-settings",
        name: "DashAuthSettings",
        component: () => import("@/views/dashboard/AuthenticationSettings.vue")
      },

    ],

  },

  {
    path: "/dashboard/product-review/:id",
    name: "AdminProductReview",
    component: () => import("@/views/dashboard/AdminProductReview.vue"),
    props: true
  },

  //catchall 404
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
