<template>
  <title>Admin Login | ProduceMart</title>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <img
          class="snippet"
          ondragstart="return false;"
          src="@/assets/img/adminImg/snippet.png"
        />
        <div class="col-lg-7">
          <div class="loginDiv">
            <div class="headLogin">
              <h3>
                <router-link to="/">
                  <img src="@/assets/img/adminImg/logo.png" />
                </router-link>
                <span>Admin Login</span>
              </h3>
            </div>
            <form @submit.prevent="handleLogin">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div class="row">
                <div class="col-lg-12 mb-4">
                  <label>Email address</label>
                  <i class="bi bi-envelope"></i>
                  <input
                    v-model="email"
                    type="email"
                    placeholder="Enter your email"
                    class="input"
                  />
                </div>
                <div class="col-lg-12 mb-4">
                  <label>Password</label>
                  <i class="bi bi-lock"></i>
                  <input
                  id="password"
                    v-model="password"
                    type="password"
                    placeholder="Enter your password"
                    class="input"
                  />
                  <span style="margin-top:5px; margin-bottom:5px">
                    <label for="view">
                    <input type="checkbox" @click="togglePassword">
                      <span style="margin-top:5px; margin-left:5px;">Show Password</span>

                  </label>
                  </span>
                </div>
                <div class="col-lg-12 mb-4 mt-2">
                  <!-- <vue-recaptcha ref="recaptcha" :siteKey="siteKey"
                    :sitekey="siteKey" @verify="onSubmit1" :loadRecaptchaScript='true'
                    @expired="onCaptchaExpired"></vue-recaptcha> -->
                  <input
                    style="margin-top:10px"
                    type="submit"
                    value="Login"
                    class="submit"
                    :disabled="loading"
                  />
                  <div
                    class="col-lg-12 mb-4 mt-2 text-center signuas"
                    v-show="loading"
                  >
                    <span class="spinner-border spinner-border-sm"></span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <img
          class="snippet2"
          ondragstart="return false;"
          ondr
          src="@/assets/img/adminImg/snippet2.png"
        />
      </div>
    </div>
  </main>
</template>
<style scoped src="@/assets/css/adminStyle.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<script>
import { API, captcha } from "@/util/APIroute";
import { mapStores } from 'pinia'
import {Auth} from "@/piniaStore/userAuth"
import {setEmail} from "@/piniaStore/email"
// import { VueRecaptcha } from 'vue-recaptcha';
import Swal from 'sweetalert2';
import {state, socket} from "@/util/socket";
export default {
  name: "Login",
  // components:{
  //   'vue-recaptcha': VueRecaptcha
  // },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loading: false,
      message: "",
      auth: false,
      // siteKey: captcha,
      // recaptcha: "",
      // verify: false
      /* userData: this.$store.state.auth.user */
    };
  },
  computed:{
    ...mapStores(Auth, setEmail)
  },
  methods: {
    // onSubmit1(response){
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: 'top-end',
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener('mouseenter', Swal.stopTimer)
    //       toast.addEventListener('mouseleave', Swal.resumeTimer)
    //     }
    //   })
    //     this.emailStore.token = response,
    //     this.emailStore.sendToken().then((res)=>{
    //       if(res){
    //         this.verify = true
    //       }else{
    //         this.verify = false
    //         this.emailStore.token = null
    //         this.recaptcha.reset()
    //         if(this.emailStore.error){
    //           Toast.fire({
    //           icon: 'error',
    //           title: 'An error occurred'

    //         })
    //         }else{
    //           Toast.fire({
    //             icon: 'warning',
    //             title: 'Please try the reCaptcha again'

    //           })
    //         }
    //       }
    //     })

    // },
    // onCaptchaExpired(){
    //   this.recaptcha.reset()
    // },
    togglePassword(){
    var x = document.getElementById('password')
    if(x.type === 'password'){
        x.type = 'text'
        // this.showPassword = true
    }else{
        x.type = 'password'
        // this.showPassword = false
    }
  },
    async handleLogin() {
      if(!state.connected){
          socket.connect()
        }
      // if(this.verify){
        this.loading = true;
      const res = await fetch(
        `${API}/signin-admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: this.email, password: this.password }),
        }
      );
      const data = await res.json();
      if (data.status == false) {
        this.loading = false;
        this.message = data.message;
        return;
      }else{
        this.auth = data.data.tfaAuth
        if(data.data.user.role !== "superadmin" &&
        data.data.user.role !== "operatingadmin" &&
        data.data.user.role !== "reportingadmin"){
          this.loading = false;
          this.message = "You're not authorized to signin as an admin, check ";
          return
        }else{
          if(this.auth){
            this.authStore.role = "admin"
            this.authStore.userData = data.data.user
            this.validateAuth()
          }else{
            data.data.user && sessionStorage.setItem("user", JSON.stringify(data.data.user));
            if (
              data.data.user.role == "superadmin" ||
              data.data.user.role == "operatingadmin" ||
              data.data.user.role == "reportingadmin"
            ) {
              this.$router.push("/dashboard/all-notifications");
            }
          }

        }
      }
      //console.log(data);
      // }


    },
    async validateAuth(){
      const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Swal.fire({
          title: 'Authentication',
          text: 'Enter your Two Factor Authentication code',
          input: 'number',
          inputLabel: "Authentication code: ",
          inputPlaceholder: "Authentication code",
          showCancelButton: true,
          confirmButtonText: 'Authenticate',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(async(result)=>{
          if(result.isConfirmed){
            if(result.value){
              this.authStore.authToken = parseInt(result.value)
              await this.authStore.validateAuth().then(async()=>{
                if(this.authStore.status){
                  Toast.fire({
                      title: this.authStore.message,
                      icon: "success"
                    }).then(()=>{
                      sessionStorage.setItem("user", JSON.stringify(this.authStore.userData));
                      if (
                        this.authStore.userData.role == "superadmin" ||
                        this.authStore.userData.role == "operatingadmin" ||
                        this.authStore.userData.role == "reportingadmin"
                      ) {
                        this.$router.push("/dashboard/all-notifications");
                        this.authStore.userData = null
                      }
                    })
                }else{
                  if(this.authStore.message){
                    Toast.fire({
                        title: this.authStore.message,
                        icon: "warning"
                      }).then(()=>{
                        this.validateAuth()
                      })
                  }
                  if(this.authStore.error){
                    Toast.fire({
                        title: this.authStore.error,
                        icon: "warning"
                      }).then(()=>{
                        this.validateAuth()
                      })
                  }
                }
              })
            }
          }
          if(result.isDismissed){
            this.$router.go()
          }
        })
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    if(JSON.parse(sessionStorage.getItem("user"))){
      this.$router.push("/dashboard/home")
    }
  },
};
</script>
